import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../app/store';

export interface ModalType {
  emailModal: boolean;
  feedBackModal: boolean;
  agentEmail: string;
  agentId: string;
  propertyId: string;
  MetricName: string;
  referenceId: string;
  title: string;
  redesignModal: boolean;
  redesignUrl: string;
}

const initialState: ModalType = {
  emailModal: false,
  feedBackModal: false,
  agentEmail: '',
  agentId: '',
  propertyId: '',
  MetricName: 'feet',
  referenceId: '',
  title: '',
  redesignModal: false,
  redesignUrl: '',
};

const modalSlice = createSlice({
  name: 'modalType',
  initialState,
  reducers: {
    openEmailModal(
      state,
      action: PayloadAction<{
        agentId: string;
        propertyId: string;
        title: string;
        referenceId: string;
      }>,
    ) {
      state.emailModal = true;
      state.title = action.payload.title;
      state.referenceId = action.payload.referenceId;

      state.agentId = action.payload.agentId;
      state.propertyId = action.payload.propertyId;
    },
    // Action to close the modal
    closeEmailModal(state) {
      state.emailModal = false;
      state.agentId = '';
    },
    openFeedBackModal(state) {
      state.feedBackModal = true;
    },
    closeFeedBackModal(state) {
      state.feedBackModal = false;
    },

    changeAreaMetric(state, action: PayloadAction<string>) {
      state.MetricName = action.payload;
    },

    handleRedesignModal(state, action: PayloadAction<boolean>) {
      state.redesignModal = action.payload;
    },
    setRedesignUrl(state, action: PayloadAction<string>) {
      state.redesignUrl = action.payload;
    },
  },
});

export const {
  openEmailModal,
  closeEmailModal,
  openFeedBackModal,
  closeFeedBackModal,
  changeAreaMetric,
  handleRedesignModal,
  setRedesignUrl,
} = modalSlice.actions;
export const openModalState = (state: RootState) => state.modalSlice.emailModal;
export const isOpenFeedBackModal = (state: RootState) =>
  state.modalSlice.feedBackModal;
export const agentEmail = (state: RootState) => state.modalSlice.agentEmail;
export const agentId = (state: RootState) => state.modalSlice.agentId;
export const propertyId = (state: RootState) => state.modalSlice.propertyId;
export const title = (state: RootState) => state.modalSlice.title;
export const referenceId = (state: RootState) => state.modalSlice.referenceId;
export const MetricName = (state: RootState) => state.modalSlice.MetricName;
export const redesignModal = (state: RootState) =>
  state.modalSlice.redesignModal;
export const redesignUrl = (state: RootState) => state.modalSlice.redesignUrl;

export default modalSlice.reducer;
