import React, { useEffect } from 'react';
import { getFromStorage, setToStorage } from '../constants/storage';
import { STORAGE_KEYS } from '../constants/storageKeys';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROLE } from '../utils/enums';
import { useAppDispatch } from '../hooks/store';
import { setToken } from '../reducers/authSlice';

export const AgentRoutes = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = getFromStorage(STORAGE_KEYS.token);
  const userData = getFromStorage(STORAGE_KEYS.userData);
  const user = userData ? JSON.parse(userData) : null;
  const tab = queryParams.get('tab');
  let auth = !!token;

  return auth ? (
    user?.role === ROLE.AGENT ? (
      <Outlet context={{ customData: tab }} />
    ) : (
      <Navigate to={'/partnerhub'} />
    )
  ) : (
    <Navigate to={'/partnerhub'} />
  );
};

export const CompanyRoutes = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const token = getFromStorage(STORAGE_KEYS.token);
  const userData = getFromStorage(STORAGE_KEYS.userData);
  const user = userData ? JSON.parse(userData) : null;
  const tokenFromQuery = queryParams.get('token');
  const role = queryParams.get('role');
  const tab = queryParams.get('tab');
  const storedToken = getFromStorage(STORAGE_KEYS.token);
  const token = tokenFromQuery || storedToken;
  const auth = !!token && (!!tokenFromQuery || !!storedToken);
  const dispatch = useAppDispatch();

  (async function saveToStorage() {
    if (tokenFromQuery) {
      await setToStorage(STORAGE_KEYS.token, JSON.stringify(tokenFromQuery));
      dispatch(
        setToken({
          token: tokenFromQuery,
        }),
      );
    }
  })();

  return auth ? (
    user?.role === ROLE.COMPANY || Number(role) === ROLE.COMPANY ? (
      <Outlet context={{ customData: tab }} />
    ) : (
      <Navigate to={'/partnerhub'} />
    )
  ) : (
    <Navigate to={'/partnerhub'} />
  );
};

export const AffiliateRoute = () => {
  const token = getFromStorage(STORAGE_KEYS.token);
  const userData = getFromStorage(STORAGE_KEYS.userData);
  const user = userData ? JSON.parse(userData) : null;
  let auth = !!token;

  return auth ? (
    user?.role === ROLE.AFFILIATE ? (
      <Outlet />
    ) : (
      <Navigate to={'/'} />
    )
  ) : (
    <Navigate to={'/'} />
  );
};

export const UserRoutes = () => {
  const token = getFromStorage(STORAGE_KEYS.token);
  const userData = getFromStorage(STORAGE_KEYS.userData);
  const user = userData ? JSON.parse(userData) : null;
  let auth = !!token;

  return auth ? (
    user?.role === ROLE.USER ? (
      <Outlet />
    ) : (
      <Navigate to={'/'} />
    )
  ) : (
    <Navigate to={'/'} />
  );
};
