import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  Reducer,
  AnyAction,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use localStorage or sessionStorage
import api from '../utils/rtk';
import {
  authReducer,
  currencySlice,
  generalReducer,
  modalSlice,
} from '../reducers';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // Choose reducers to persist
};

const appReducer = combineReducers({
  auth: authReducer,
  general: generalReducer,
  modalSlice: modalSlice,
  currencySlice: currencySlice,

  [api.reducerPath]: api.reducer,
});

const persistedReducer = persistReducer(persistConfig, appReducer);
export type RootState = ReturnType<typeof persistedReducer>;
const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/resetAuth') {
    state = {} as RootState;
  }
  return persistedReducer(state, action);
};
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware),
});
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
