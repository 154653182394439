import io from 'socket.io-client';

// This is the socket URL
// export const Socket_URL = `https://getpropmicrosocket.appgrowthcompany.com/`; // dev

// export const Socket_URL = `https://getprop-operational-staging.appgrowthcompany.com/`; // staging

export const Socket_URL = process.env.REACT_APP_Socket_URL; // sandbox

export const socket = io(Socket_URL, {
  autoConnect: false,
});
