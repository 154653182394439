import React, { useEffect } from 'react';
import { getFromStorage } from '../constants/storage';
import { STORAGE_KEYS } from '../constants/storageKeys';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { ROLE } from '../utils/enums';

const CompanyProtectedRoutes = () => {
  const token = getFromStorage(STORAGE_KEYS.token);
  const userData = getFromStorage(STORAGE_KEYS.userData);
  const user = userData ? JSON.parse(userData) : null;
  let auth = !!token;

  return auth ? (
    user?.role === ROLE.AGENT || user?.role === ROLE.COMPANY ? (
      <Outlet />
    ) : (
      <Navigate to={'/'} />
    )
  ) : (
    <Navigate to={'/'} />
  );
};

export default CompanyProtectedRoutes;
